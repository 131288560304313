
  import { Action, Getter } from "vuex-class";
  import { bus } from "@/main";
  import { Component, Mixins, Ref } from "vue-property-decorator";
  import { FormBase, FormInput, FormSubmit, FormCheckbox, FormSelect, FormPhone } from "@/components/forms";
  import { CreateSellerPayload, Seller } from "@/store/modules/seller.store";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { formatErrors } from "@/utils/formatters";
  import { activityIsDossier } from "@/utils/model-helpers";
  import { ContactType, UpdateActivitySellerPayload } from "@/store/modules/activity-seller.store";
  import { ActivitySeller } from "@/store/modules/activity-seller.store";
  import { contactTypeOptions, SelectOption } from "@/utils/select-options";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormInput,
      FormSelect,
      FormPhone,
      FormSubmit,
      FormCheckbox,
    },
  })
  export default class SellerUpdateModal extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;
    @Action("seller/update") updateSeller!: ({ payload, id }: { payload: CreateSellerPayload; id: number }) => Promise<Seller>;
    @Action("activity-seller/update") updateActivitySeller!: (payload: UpdateActivitySellerPayload) => Promise<number>;

    @Ref() form!: FormClass;

    seller: Seller | null = null;

    activitySeller: ActivitySeller | null = null;

    show: boolean = false;

    dashboard_access: boolean | null = false;

    contactType: ContactType = ContactType.owner;

    payload: CreateSellerPayload = {
      email: null,
      phone: "",
      first_name: "",
      last_name: "",
    };

    get isDossier() {
      return activityIsDossier(this.activity);
    }

    get filteredContactTypes() {
      return contactTypeOptions.filter((ct: SelectOption) =>
        ([ContactType.owner, ContactType.contact, ContactType.buyer, ContactType.potentialBuyer] as string[]).includes(ct.value as string),
      );
    }

    get canGrantDashboardAccess() {
      return this.payload.email && this.payload.email !== "" && this.isDossier && [ContactType.owner, ContactType.contact].includes(this.contactType);
    }

    mounted() {
      bus.$off("show-update-seller");
      bus.$on("show-update-seller", (seller: Seller, activitySeller: ActivitySeller) => {
        this.seller = seller;
        this.activitySeller = activitySeller;

        this.payload.email = seller.email;
        this.payload.phone = seller.phone;
        this.payload.first_name = seller.first_name;
        this.payload.last_name = seller.last_name;
        this.dashboard_access = activitySeller.dashboard_access;
        this.contactType = activitySeller.type;

        this.show = true;
      });
    }

    async submit(form: FormClass) {
      try {
        await this.updateSeller({ payload: this.payload, id: this.seller!.id });

        if (this.activitySeller && (this.activitySeller.dashboard_access !== this.dashboard_access || this.activitySeller.type !== this.contactType)) {
          await this.updateActivitySeller({ ...this.activitySeller, dashboard_access: this.dashboard_access, type: this.contactType });
        }

        this.$toast.open({ message: this.$t("views.seller.update.success") as string, type: "success", position: "bottom-right" });

        this.handleClose();
      } catch (e) {
        this.errorResponse = formatErrors(e);
      } finally {
        form.reset();
      }
    }

    handleClose() {
      this.seller = null;

      this.activitySeller = null;

      this.payload = {
        email: null,
        phone: "",
        first_name: "",
        last_name: "",
      };

      this.errorResponse = {
        status: 0,
        errors: [],
      };

      this.show = false;
    }
  }
